import React from 'react'
import moment from 'moment'
import { HotTable } from '@handsontable/react'
import { DATE_FORMAT, MONEY_FORMAT, negativeValueRenderer } from './App'
import Handsontable from 'handsontable'

export class Transactions extends React.Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()

    this.settings = {
      currentRowClassName: 'currentRow',
      minSpareRows: 5,
      stretchH: 'all',
      allowEmpty: false,
      outsideClickDeselects: false,

      licenseKey: 'non-commercial-and-evaluation',
      columnSorting: false,
      colHeaders: [
        'Date',
        'Description',
        'Category',
        'Value (£)',
        'Balance (£)'
      ],
      enterMoves: () => {
        let ref = this.table.current
        const hot = ref.hotInstance
        const maxCol = hot.getCellMeta(0, 0).columns.length - 1
        const [[, col]] = hot.getSelected()
        if (col >= maxCol - 1) {
          console.log('---------> 1')
          return { row: 1, col: -col }
        } else {
          console.log('---------> 2')
          return { row: 0, col: 1 }
        }
      },
      data: this.props.transactions,
      rowHeaders: false,

      columns: [{
        data: 'Date',
        type: 'date',
        dateFormat: DATE_FORMAT,
        correctFormat: true,
        defaultDate: moment(new Date()).format(DATE_FORMAT)
      }, {
        data: 'description'// 2nd column is simple text, no special options here
      }, {
        data: 'category',
        type: 'autocomplete',
        source: (part, cb) => {
          let options = [...new Set(this.props.data.map(a => a.category))].filter(a => a && a.includes(part))
          cb(options)
        },
        strict: false
      }, {
        data: 'value',
        renderer: negativeValueRenderer,
        ...MONEY_FORMAT
      }, {
        data: 'balance',
        readOnly: true,
        ...MONEY_FORMAT,
        type: 'numeric',
        renderer: function(instance, td, row, col, prop, value, cellProperties) {
          let data = instance.getData()
          if (!data[row][0]) {
            return td
          }
          let total = 0
          for(let i=0;i<=row;i++) {
            total += data[i][3]
          }
          return negativeValueRenderer(instance, td, row, col, prop, total, cellProperties)
          // return td;
        }
      }]
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !this.lastData || this.lastData !== nextProps.data
  }

  render() {
    this.lastData = this.props.data
    return (
      <HotTable ref={this.table} id={'transactionsTable'} settings={this.settings} {...this.props}/>
    )
  }
}

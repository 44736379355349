import React from 'react'
import './App.css'
import 'handsontable/dist/handsontable.full.css'
import Handsontable from 'handsontable'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import moment from 'moment'
import debounce from 'debounce'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'

import { HotTable } from '@handsontable/react'
import { Transactions } from './Transactions'

const FREQUENCY_MAP = {
  'monthly': 'M',
  'weekly': 'w',
  'annually': 'y'
}

export const DATE_FORMAT = 'DD/MM/YYYY'

let chartLineColors = [
  '#2d95ca',
  '#efb925',
  '#da4e99',
  '#4cc873',
  '#745cbd',
  '#ea7136',
  '#666666',
  '#e68ee0',
  '#218c35',
  '#b0b510',
  '#904064'
]

const SCHEDULE_TABLE_SORTING = {
  sortEmptyCells: false,
  initialConfig: {
    column: 1,
    sortOrder: 'asc'
  }
}

export const MONEY_FORMAT = {
  type: 'numeric',
  numericFormat: {
    pattern: '0,0.00',
    culture: 'de-GB'
  }
}

Number.prototype.toMoney = function (decimals = 2, decimal_sep = '.', thousands_sep = ',') {
  var j
  var n = this,
    c = isNaN(decimals) ? 2 : Math.abs(decimals), //if decimal is zero we must take it, it means user does not want to show any decimal
    d = decimal_sep || '.', //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

    /*
    according to [https://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
    the fastest way to check for not defined parameter is to use typeof value === 'undefined'
    rather than doing value === undefined.
    */
    t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep, //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

    sign = (n < 0) ? '-' : '',

    //extracting the absolute value of the integer part of the number and converting to string
    i = parseInt(n = Math.abs(n).toFixed(c)) + '',

    j = ((j = i.length) > 3) ? j % 3 : 0
  return sign + '£' + (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
}

const FUTURE_TABLE_SETTINGS = {
  currentRowClassName: 'currentRow',
  columnSorting: true,
  stretchH: 'all',
  outsideClickDeselects: false,
  readOnly: true,
  readOnlyCellClassName: '',

  licenseKey: 'non-commercial-and-evaluation',
  colHeaders: [
    'Date',
    'Description',
    'Value (£)',
    'Current (£)'
  ],
  data: [],
  rowHeaders: false,
  columns: [{
    data: 'date',
    type: 'date',
    dateFormat: DATE_FORMAT,
    correctFormat: true
  }, {
    data: 'description'// 2nd column is simple text, no special options here
  }, {
    data: 'value',
    ...MONEY_FORMAT
  }, {
    data: 'current',
    ...MONEY_FORMAT
  }],
  cells: function (row, col) {
    const cellProperties = {}
    if (col === 2 || col === 3) {
      cellProperties.renderer = negativeValueRenderer
    }
    return cellProperties
  }
}

export function negativeValueRenderer(instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.NumericRenderer.apply(this, arguments)
  if (parseInt(value, 10) < 0) {
    td.style.color = 'red'
  } else {
    td.style.color = 'blue'
  }
}

function occurrencesValueRenderer(instance, td, row, col, prop, value, cellProperties) {
  let v = parseInt(value, 10)
  Handsontable.renderers.NumericRenderer.apply(this, arguments)
  if (v === -1) {
    td.style.color = 'white'
  }
}

const dateToMoment = d => moment(d, DATE_FORMAT)

class ScheduleTable extends React.Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()

    this.scheduleTableSettings = {
      currentRowClassName: 'currentRow',
      minSpareRows: 5,
      stretchH: 'all',

      licenseKey: 'non-commercial-and-evaluation',
      columnSorting: SCHEDULE_TABLE_SORTING,
      colHeaders: [
        'Next',
        'Description',
        'Value (£)',
        'Frequency',
        'Repeats',
        'Category'
      ],
      enterMoves: () => {
        let ref = this.table.current
        const hot = ref.hotInstance
        const maxCol = hot.getCellMeta(0, 0).columns.length - 1
        const [[, col]] = hot.getSelected()
        if (col >= maxCol) {
          return { row: 1, col: -maxCol }
        } else {
          return { row: 0, col: 1 }
        }
      },
      data: [],
      rowHeaders: false,
      columns: [{
        data: 'nextDate',
        type: 'date',
        dateFormat: DATE_FORMAT,
        correctFormat: true,
        defaultDate: moment(new Date()).format(DATE_FORMAT)
      }, {
        data: 'description'// 2nd column is simple text, no special options here
      }, {
        data: 'value',
        ...MONEY_FORMAT
      }, {
        data: 'frequency',
        editor: 'select',
        selectOptions: ['monthly', 'weekly', 'annually']
      }, {
        data: 'occurrences',
        type: 'numeric'
      }, {
        data: 'category',
        type: 'autocomplete',
        source: (part, cb) => {
          let options = [...new Set(this.props.data.map(a => a.category))].filter(a => a && a.includes(part))
          cb(options)
        },
        strict: false
      }],
      cells: function (row, col) {
        const cellProperties = {}
        if (col === 2) {
          cellProperties.renderer = negativeValueRenderer
        } else if (col === 4) {
          cellProperties.renderer = occurrencesValueRenderer
        }
        return cellProperties
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !this.lastData || this.lastData !== nextProps.data
  }

  render() {
    this.lastData = this.props.data
    return (
      <HotTable ref={this.table} id={'scheduleTableChild'} {...this.props} settings={this.scheduleTableSettings}/>
    )
  }
}

const upgradeDataSchema = old => {
  let data = { ...old }
  let ts = data.transactionSchedules
  if (ts && ts.length && !ts[0].category) {
    data.transactionSchedules = ts.map(a => ({ ...a, category: '' }))
  }
  return data
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      versions: [],
      selectedVersion: 'latest',
      newFileName: '',
      until: moment(new Date()).add(1, 'y').format(DATE_FORMAT),
      dataLoaded: false,
      data: {
        transactionSchedules: [],
        transactions: []
      },
      categoriesPieChartData: [],
      totalExpenses: '£ 0.00'
    }

    this.afterScheduleTableChange = this.afterScheduleTableChange.bind(this)

    this.state.rawData = JSON.stringify(this.state.data, null, 2)

    this.refresh = debounce(this.refreshImmediate.bind(this), 1000)
    this.scheduleTable = React.createRef()
    this.futureTable = React.createRef()
    this.transactionTable = React.createRef()
  }

  async componentDidMount() {
    this.load('latest')
    const response = await fetch('/api/versions')
    if (response.status !== 200) {
      console.log('Looks like there was a problem. Status Code: ' +
        response.status)
      return
    }
    this.setState({
      versions: (await response.json()).sort()
    })

  }

  refreshImmediate() {
    console.log('-----> refreshing')
    const max = moment(this.state.until, DATE_FORMAT)

    let current = this.state.data.transactions.reduce((acc, { value }) => value ? acc + value : acc, 0)

    const categoriesPieData = {}

    const future = this.state.data.transactionSchedules.reduce((acc, {
      occurrences,
      description,
      value,
      nextDate: nd,
      frequency,
      category
    }) => {
      const repeatForever = !occurrences || occurrences === -1
      if (!value || !nd || (repeatForever && !frequency)) {
        return acc
      }
      const newTransaction = date => ({ date: date.clone(), description, value })
      const nextDate = typeof nd === 'string' && dateToMoment(nd) || nd.clone()
      while (nextDate.isBefore(max) && (repeatForever || occurrences-- > 0)) {
        if (value < 0) {
          categoriesPieData[category] = (categoriesPieData[category] || 0) + Math.abs(value)
        }
        acc.push(newTransaction(nextDate))
        nextDate.add(1, FREQUENCY_MAP[frequency])
      }
      return acc
    }, [])
      .sort(({ date: a }, { date: b }) => a.unix() - b.unix())
      .map(a => ({
        ...a,
        date: a.date.format(DATE_FORMAT),
        current: (current += a.value)
      }))

    FUTURE_TABLE_SETTINGS.data = future

    const total = Object.values(categoriesPieData).reduce((acc, next) => acc + next, 0)
    this.setState({
      rawData: JSON.stringify(this.state.data, null, 2),
      totalExpenses: total.toMoney(),
      categoriesPieChartData: Object.entries(categoriesPieData).sort(([, a], [, b]) => b - a).map(([name, value]) => ({
        name: `${name} ${value.toMoney()}`,
        value: Math.round(value / total * 10000) / 100
      }))
    })
  }

  async save() {
    console.log('---------> saving:', this.state.newFileName)
    const response = await fetch(`/api/save?version=${this.state.newFileName}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.data)
    })
    this.setState({
      versions: await response.json()
    })
  }

  async load(version) {
    console.log('---------> load:', version)
    const response = await fetch(`/api/load?version=${version}`)
    if (response.status !== 200) {
      console.log('Looks like there was a problem. Status Code: ' +
        response.status)
      return
    }
    let data = await response.json()
    this.setData(data)
  }

  setData(candidate, immediate = false) {
    const data = upgradeDataSchema(candidate)
    if (!data.transactions) {
      data.transactions = []
    }
    this.setState({
      dataLoaded: true,
      data
    })
    if (immediate) {
      this.refreshImmediate()
    } else {
      this.refresh()
    }
  }

  render() {
    return (
      <div className="App">
        {this.state.dataLoaded ? this.renderApp() : this.renderLoadingData()}
      </div>
    )
  }

  renderLoadingData() {
    return (
      <div style={{ width: '300px', margin: '0 auto' }}>
        <div>loading data...</div>
      </div>
    )
  }

  afterScheduleTableChange(change, source) {
    if (source !== 'loadData') {
      this.refresh()
    }
  }

  execute() {
    const hot = this.futureTable.current.hotInstance
    const data = hot.getSourceData()
    const oldData = JSON.stringify(this.state.data)
    try {
      let { transactionSchedules, transactions } = this.state.data
      let nextTransactionIndex = transactions.findIndex(a => !a.Date)
      hot.getSelected().forEach(([start, , end]) => {
        for (let i = start; i <= end; i++) {
          let future = data[i]
          const { date, description, value } = future
          const scheduleIndex = transactionSchedules.findIndex(a => a.nextDate === date && a.description === description && a.value === value)
          const schedule = transactionSchedules[scheduleIndex]
          if (!schedule) {
            throw new Error(`Something wrong, schedule not found for future: ${JSON.stringify(future)}`)
          }
          let { category, nextDate, frequency, occurrences } = schedule
          transactions.splice(nextTransactionIndex++, 0, { Date: date, description, category, value })

          if (typeof occurrences === 'number' && occurrences > 0) {
            occurrences--
          }
          if (occurrences === 0) {
            transactionSchedules.splice(scheduleIndex, 1)
          } else {
            const nd = dateToMoment(nextDate)
            nd.add(1, FREQUENCY_MAP[frequency])
            schedule.nextDate = nd.format(DATE_FORMAT)
            schedule.occurrences = occurrences
          }
        }
      })
      transactions = transactions.filter(a => a.Date).sort((a, b) => a.Date === b.Date ? 0 : (dateToMoment(a.Date).isAfter(dateToMoment(b.Date)) ? 1 : -1))
      this.setData({
        transactionSchedules,
        transactions
      }, true)
      hot.deselectCell()
    } catch (e) {
      console.error(e)
      this.setData(JSON.parse(oldData))
    }
  }

  deleteTransaction() {
    const hot = this.transactionTable.current.table.current.hotInstance
    hot.alter('remove_row', hot.getSelected().map(([start, , end]) => [start, end - start + 1]))
  }

  renderApp() {
    return <header className="App-header">
      <Tabs>
        <TabList>
          <Tab>Projection</Tab>
          <Tab>Statement</Tab>
          <Tab>Schedules</Tab>
          <Tab>Graphs</Tab>
          <Tab>JSON</Tab>
        </TabList>


        <TabPanel>
          <div className="top-controls" style={{ justifyContent: 'flex-end' }}>
            <button onClick={() => this.execute()}>Execute</button>
          </div>
          <div>
            <HotTable ref={this.futureTable} id="futureTable" settings={FUTURE_TABLE_SETTINGS}/>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="top-controls" style={{ justifyContent: 'flex-end' }}>
            <button onClick={() => this.deleteTransaction()}>Delete</button>
          </div>
          <Transactions ref={this.transactionTable} width={'100%'} data={this.state.data.transactions}
                        afterChange={() => this.refresh()}/>
        </TabPanel>


        <TabPanel>
          <div className="top-controls">
            <button onClick={() => this.scheduleTable.current.table.current.hotInstance.alter('insert_row', 0, 1)}>Add
            </button>
            <div>
              <input value={this.state.newFileName}
                     onChange={a => this.setState({ newFileName: a.target.value })}/>
              <button onClick={() => this.save()}>Save</button>
            </div>
            <div>
              <select onChange={a => {
                this.setState({ selectedVersion: a.target.value })
                this.load(a.target.value)
              }} value={this.state.selectedVersion}>
                {this.state.versions.map(v => <option key={v} value={v}>{v}</option>)}
              </select>
            </div>
          </div>
          <ScheduleTable afterChange={this.afterScheduleTableChange} ref={this.scheduleTable} id="scheduleTable"
                         data={this.state.data.transactionSchedules}/>
        </TabPanel>


        <TabPanel>
          <p>Total expenses until {this.state.until}: <span style={{ color: 'red' }}>{this.state.totalExpenses}</span>
          </p>
          <p>Category Breakdown:</p>
          <PieChart width={800} height={600}>
            <Pie dataKey="value" isAnimationActive={false} data={this.state.categoriesPieChartData} cx={400} cy={300}
                 outerRadius={200} label>
              {
                this.state.categoriesPieChartData.map((entry, index) =>
                  <Cell key={index} fill={chartLineColors[index % chartLineColors.length]}/>)
              }
            </Pie>

            <Tooltip/>
          </PieChart>

        </TabPanel>

        <TabPanel>
          <button onClick={() => this.setData(JSON.parse(this.state.rawData))}>Load from JSON</button>
          <textarea style={{
            width: '100%',
            height: '80vh'
          }} value={this.state.rawData} onChange={a => this.setState({ rawData: a.target.value })}/>
        </TabPanel>
      </Tabs>

    </header>
  }
}

export default App

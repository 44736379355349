import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css'
import App from './App'

const PASSWORD = 'emdsdb21emdsdb21'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: ''
    }
  }

  render() {
    return (
      <div style={{
        width: '400px',
        margin: '0 auto'
      }}>
        <form>
          <table>
            <tr>
              <td>Password:</td>
              <td><input value={this.state.password} type={'password'}
                         onChange={a => this.setState({ password: a.target.value })}/></td>
              <td>
                <button onClick={() => window.sessionStorage.setItem('password', this.state.password)}>Login
                </button>
              </td>
            </tr>
          </table>
        </form>
      </div>
    )
  }
}

ReactDOM.render(
  <React.StrictMode>
    {window.sessionStorage.getItem('password') === PASSWORD ? <App/> : <Login/>}
  </React.StrictMode>,
  document.getElementById('root')
)
